import React, { useEffect, useMemo } from "react";
import Homepage from "templates/v2/HomePage";
import HomepagePractitioner from "templates/v2/HomePagePractitioner";
import LayoutEcomm from "@/components/Layout/LayoutEcomm";
import { getBannerList, getMedicineCategoryListWithoutAuth } from "services/medicine.service";
import { useRouter } from "next/router";
import * as moengage from "services/moengage.service";
import mo_engage_webinar from "../assets/mo_engage/webinar_journey";
import mo_engage_onboarding from "../assets/mo_engage/onboarding";
import { getBannerUrl } from "core/homePage";
import { useVerifyDocumentsModalContext } from '@/components/context/VerifiyDocumentsModalContext';
import { NOT_ALLOWED_USER_VERIFICATION_STATUS, CUSTOMER_TYPE } from "constant";
import { getAuth, isUnauthorizedRequest } from "services/identity.service";
import { META_HOME_DESCRIPTION, META_HOME_TITLE, META_HOME_KEYWORDS } from "constant/meta_info/homepage";
import { getDashboardConstant } from "services/reseller.service";
import NirogSeoSchemaHead from "@/components/common/NirogSeoSchemaHead";

function transformBannerItems(items, router) {
  return items?.map(item => ({
    id: item.id,
    name: item.name,
    home_image: `${process.env.NEXT_PUBLIC_BUCKET_URL}${item.home_image}`,
    app_image: `${process.env.NEXT_PUBLIC_BUCKET_URL}${item.app_image}`,
    onBannerClick: () => {
      const url = getBannerUrl({
        linkingType: item.linking_type,
        bannerLinkingId: item.banner_linking_id,
        pageUrl: item.page_url,
        bannerType: item.banner_type,
      });
      router.push(url);
    },
  }));
}

export default function Home({ categoryList, bannerList, dailyDealBanner, dashboard, customerType }) {
  const router = useRouter();
  const WebinarEntity = mo_engage_webinar.entity;
  const entity = mo_engage_onboarding.entity;
  const auth = getAuth();
  const { handlerSetShowModalFor } = useVerifyDocumentsModalContext();

  useEffect(() => {
  }, [dashboard]);

  useEffect(() => {
    if (NOT_ALLOWED_USER_VERIFICATION_STATUS[auth?.user?.userType]?.includes(auth?.isVerifyCheckedKey)) {
      handlerSetShowModalFor();
    }
  }, [auth, handlerSetShowModalFor]);

  const transformBanner = useMemo(() => transformBannerItems(bannerList, router), [bannerList, router]);
  const transformDailyBanner = useMemo(() => transformBannerItems(dailyDealBanner, router), [dailyDealBanner, router]);

  useEffect(() => {
    if (auth?.token) {
      navigator.geolocation.getCurrentPosition(() => { });
    }
    moengage.trackEvent(entity.site_opened.event_name, {});
  }, [auth?.token, entity.site_opened.event_name]);

  return (
    <>
      <NirogSeoSchemaHead
          pageTitle={META_HOME_TITLE}
          metaContent={[
              { name: "title", content: META_HOME_TITLE, property: "og:title", },
              { name: "description", content: META_HOME_DESCRIPTION, property: "og:description", },
              { name: "keywords", content: META_HOME_KEYWORDS, property: "og:keywords"},
              { name: "og:title", content: META_HOME_TITLE, property: "og:title", },
              { name: "og:description", content: META_HOME_DESCRIPTION, property: "og:description", },
              { name: "og:type", content: "Website", property: "og:type", },
              { name: "og:url", content: `${process.env.NEXT_PUBLIC_PAGE_BASE}${router.asPath}`, property: "og:url", },
              { name: "og:site_name", content: "NirogStreet", property: "og:site_name", },
              { name: "og:keywords", content: META_HOME_KEYWORDS, property: "og:keywords", },
          ]}
          links={[
              { rel: "shortcut icon", href: "/favicon.ico" }
          ]}
          withOrganizationSchema={true}
          schemaEnv='production'
      />

      <LayoutEcomm categoryList={categoryList} contentClassName="pb-0" appointmentBenefitsList={dashboard?.request_appointment} showPincodeModal={auth?.user?.userType == 1}>
        {customerType === CUSTOMER_TYPE.INDIVIDUAL
          ? <Homepage bannerList={transformBanner} dailyDealBanner={transformDailyBanner} dashboard={dashboard} />
          : <HomepagePractitioner bannerList={transformBanner} dailyDealBanner={transformDailyBanner} customerType={customerType} dashboard={dashboard} />
        }
      </LayoutEcomm>
    </>
  );
}


export const getServerSideProps = async ({ req }) => {
  let auth = { token: "", user: { id: "", userType: "" } };
  if (!isUnauthorizedRequest(req.cookies.AUTH)) {
    auth = JSON.parse(req.cookies.AUTH);
  }

  const type = isUnauthorizedRequest(req.cookies?.AUTH) 
    ? '' 
    : (JSON.parse(req.cookies.AUTH)?.user?.userType === 1 ? '' : 'practitioner');

  const [dashboardResponse] = await Promise.all([
    getDashboardConstant(type)
  ]);
  
  const bannerResponse = await getBannerList(1, auth?.user?.id || null, "B2B", auth?.token || null);
  const dailyDealBanner = await getBannerList(4, auth?.user?.id || null, "B2B", auth?.token || null);
  const categoryListResponse = await getMedicineCategoryListWithoutAuth();

  return {
    props: {
      bannerList: bannerResponse?.entity || [],
      dailyDealBanner: dailyDealBanner?.entity || [],
      categoryList: categoryListResponse?.data?.entity || [],
      dashboard: dashboardResponse?.entity || [],
      customerType: type === 'practitioner' ? CUSTOMER_TYPE.PRACTITIONER : CUSTOMER_TYPE.INDIVIDUAL,
    }
  };
};
