import React from 'react';
import Container from 'atoms/Container';
import * as moengage from "services/moengage.service";
import { OLYMPIAD_START_DATE, USER_TYPES } from 'constant';
import { PRACTITIONER_COLOR_BOX_SECTION, FAQ_HEADING_HOME_PAGE, TRUSTED_BY_EXPERIENCED_DOCTOR_HEADING_HOME_PAGE } from 'constant/homepage';
import dynamic from 'next/dynamic';
import useWindowSize from 'customHook/useWindowSize'; // Added import for useWindowSize
import ga_homepage_practitioner from "../../assets/mo_engage/homepage_practitioner.json";
import LoadingStatic from 'components/Loader/LoadingStatic';
import PractitionerGetStarted from 'molecules/PractitionerGetStarted';

import { getAuth } from 'services/identity.service';
import { useRouter } from 'next/router';
import { redirectionAfterLogin } from 'core/common/redirectionAfterLogin';
import { captureEvent } from 'services/analytics.service';

const BannerSection = dynamic(() => import("organisms/BannerSection"));
const OlympiadBanner = dynamic(() => import('molecules/OlympiadBanner'));
const mo_engage_olympiad = dynamic(() => import("../../assets/mo_engage/olympiad"));
const DailyDealBanner = dynamic(() => import('organisms/DailyDealBanner'));
const ScaleMetricsListV2 = dynamic(() => import('molecules/ScaleMetricsListV2'));
const IconWithLabelDescriptionUrl = dynamic(() => import('molecules/IconWithLabelDescriptionUrl'));
const ShortStoryCard = dynamic(() => import('molecules/ShortStoryCard'));
const MedicineSectionV2 = dynamic(() => import('organisms/MedicineSectionV2'));
const ShortStoryCore = dynamic(() => import('core/homePage/ShortStoryCore'));
const OnlineCoursesSectionCore = dynamic(() => import('core/homePage/OnlineCoursesSectionCore'));
const FeedSliderCore = dynamic(() => import('@/components/feeds/FeedSliderCore'));
const Faq = dynamic(() => import('organisms/Faq'));
const loading = () => <LoadingStatic className=' h-40' />;
const BrandSectionCore = dynamic(() => import('core/homePage/BrandSectionCore'), { loading });
const PartnerSection = dynamic(() => import('organisms/PartnerSection'), { loading });

const HomePagePractitioner = ({ bannerList, dailyDealBanner, dashboard, userDetails }) => {

    const olympiadEntity = mo_engage_olympiad.entity;
    const [width] = useWindowSize();
    const router = useRouter();
    const handlerLearnMore = () => {
        moengage.trackEvent(olympiadEntity.Olympiad_banner_homepage.event_name, {})
    }
    const auth = getAuth();
    const { category, entity } = ga_homepage_practitioner
    const events = ga_homepage_practitioner.entity;

  
    return (
        <div className="bg-basic-white">
            <Container type='static' className='px-4'>
                <OlympiadBanner
                    currentDateUpdate="2nd day/30 days"
                    handlerLearnMore={handlerLearnMore}
                    learnMoreRoute="/olympiad"
                    time={OLYMPIAD_START_DATE}
                    className="mb-3 w-full "
                />
            </Container>
            <DailyDealBanner bannerData={dailyDealBanner} />

            <BannerSection sliderList={bannerList} />

            {![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(auth?.user?.userType) && <Container type='static' className='px-5'>                
                <IconWithLabelDescriptionUrl
                    containerClass='grid grid-cols-2 md:grid-cols-4 gap-3.5 md:gap-7 justify-between my-6 md:my-8'
                    iconClass='w-7.5 h-7.5'
                    list={PRACTITIONER_COLOR_BOX_SECTION}
                    iconContainerClass='h-7 md:mb-4.5 '
                />
                 <ScaleMetricsListV2
                    isVerticalLine={true}
                    heading='Learn and Engage with Ayurveda Experts'
                    className=''
                    list={[
                        { id: 1, count: dashboard?.doctors + '+', title: 'Doctors' },
                        { id: 2, count: dashboard?.practitioner + '+', title: 'Practitioners' },
                        { id: 3, count: dashboard?.medicines + '+', title: 'Medicines' },
                        { id: 4, count: dashboard?.clinics + '+', title: 'Ayurveda Clinics' }]}
                />
            </Container>}

            {![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(auth?.user?.userType) && <ShortStoryCard
                containerClass='bg-primary1-100'
                className='!shadow-none my-8 md:px-4'
                classContent='md:max-w-[480px]'
                classImage='md:max-w-[550px]'
                isColReverse={false}
                heading={dashboard?.medicine_quotes?.title}
                description={dashboard?.medicine_quotes?.description}
                linkName={dashboard?.medicine_quotes?.cta}
                link={auth ? '/medicines' : '/login-practitioner'}
                onLinkClick={event => {
                    redirectionAfterLogin({ event, auth, router, redirectUrlLoggedIn: '/medicines', redirectUrlLoggedOut: '/login-practitioner' })
                    captureEvent(
                        category,
                        entity.Doctor_Page_Explore_Medicines_Click.event_name,
                        entity.Doctor_Page_Explore_Medicines_Click.event_name,
                        {
                            ...events.attributes,
                            login_state: auth?.user ? true : false,
                        }
                    );
                }}
                image={`${process.env.NEXT_PUBLIC_PHP_BUCKET_URL}${width > 540 ? dashboard?.medicine_quotes?.webImage : dashboard?.medicine_quotes?.mobileImage}`}
            />}

            <Container type='static'>
                <BrandSectionCore />
            </Container>

            <MedicineSectionV2 heading="Top Selling Medicines" link='/medicines/allMedicines' />
            {![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(auth?.user?.userType) && <ShortStoryCard
                containerClass='bg-basic-white'
                className='!shadow-none md:px-4'
                headingClass='md:text-24-34'
                classContent='md:max-w-[425px]'
                classImage='md:max-w-[550px]'
                isColReverse={true}
                heading={dashboard?.reseller_program?.title}
                description={dashboard?.reseller_program?.description}
                linkName={dashboard?.reseller_program?.cta}
                link='/reseller'
                image={`${process.env.NEXT_PUBLIC_PHP_BUCKET_URL}${width > 540 ? dashboard?.reseller_program?.webImage : dashboard?.reseller_program?.mobileImage}`}
                onLinkClick={event => {
                    captureEvent(
                        category,
                        entity.Doctor_Page_Nirog_Partner_Click.event_name,
                        entity.Doctor_Page_Nirog_Partner_Click.event_name,
                        {
                            ...events.attributes,
                            login_state: auth?.user ? true : false,
                        }
                    );
                }}
            />}

            {![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(auth?.user?.userType) && <ShortStoryCore dashboard={dashboard} heading='Sole Destination for Ayurveda Professionals!' />}

            {![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(auth?.user?.userType) && <OnlineCoursesSectionCore dashboard={dashboard} heading='Explore our Online Courses & Join Latest Webinars' />}

            <div>
                {auth && ![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(auth?.user?.userType) && <FeedSliderCore className='bg-primary1-100' heading='Clinical Discussion' link='/feeds' />}
            </div>

            <PartnerSection className='pb-7 md:my-7 mt-5' heading={TRUSTED_BY_EXPERIENCED_DOCTOR_HEADING_HOME_PAGE} practitionerHomepage={true} list={dashboard?.healing_story?.map(item => ({
                id: item?.author,
                name: item?.author,
                description: item?.details,
                qualification: item?.qualification,
                experience: item?.experience,
                avatar: process.env.NEXT_PUBLIC_PHP_BUCKET_URL + item?.profileImg,
            }))} theme='v2' />

            <Faq className='px-5 py-7.5 bg-gray-100' heading={FAQ_HEADING_HOME_PAGE} list={dashboard?.faqs?.map(item => ({
                id: item?.question,
                title: item?.question,
                description: item?.answer,
            }))} theme='v2' />

            <div>
                {!auth && <PractitionerGetStarted
                    link='/login-practitioner'
                    heading={dashboard?.join_practitionar?.title}
                    description={dashboard?.join_practitionar?.description}
                />}
            </div>
        </div>
    )
}

export default HomePagePractitioner